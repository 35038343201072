.users-page {
  padding-inline: 20px;
  padding-bottom: 20px;
  display: flex;
  flex: 1;
  align-self: stretch;
  flex-direction: column;
  font-family: Arial, sans-serif;
  transition: padding-inline 0.3s ease, padding-bottom 0.3s ease;
}

@media (max-width: 700px) {
  .users-page {
    padding-inline: 0px;
    padding-bottom: 10px;
    /* Optional: Adjust padding-bottom for small screens */
  }
}


.user-row {
  transition: background-color 0.5s;
  cursor: pointer;
}

.user-row:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.status {
  height: 15px;
  width: 15px;
  border-radius: 100px;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0px 4px 6px rgba(0, 255, 234, 0.3);
  /* align-self: center; */
}

.status.true {
  background-color: #9ccfa8;
}

.status.true:hover {
  background-color: #61bb76;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
}

.status.false {
  background-color: #b68084;
}

.status.false:hover {
  background-color: #e78891;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
}

.TableContainer {
  overflow-y: auto;
  overflow-x: auto;
  margin-bottom: 10px;
}

.TableContainer::-webkit-scrollbar {
  height: 8px;
}

.TableContainer::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 8px;
}

.TableContainer::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}